#avis {
    margin-bottom: $gutter-width;
    padding: 0 !important;
    background: none !important;
    .titre_avis {
        @extend .title-2;
    }
    .bloc-avis {
        min-height: 209px !important;
    }
}